.fooCon{
    width: 100%;
    background-color: black;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;  
    margin-top: 20px;  
    color: white;
}

.links{
    width: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.links>img{
    width: 50px;
    height: 50px;
    object-fit: none;
}