.contactus {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}

.contactus>form {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.contactus>form>label {
    margin: 5px;
    font-weight: bold;
    font-style: italic;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.contactus>form>input {
    margin: 10px;
}

.contactus>form>input {
    width: 300px;
    height: 50px;
    border-radius: 7px;
    border: 1px solid grey;
    font-size: 15px;
    font-family: Verdana, Geneva, Tahoma, sans-serif
}

.btn {
    background-color: #cc9661;
    border: none;
    font-size: 18px;
    height: 50px;
    width: 150px;
    border-radius: 10px;
    align-self: center;
    align-content: center;
    color: white;
    cursor: pointer;

}

.contactus>h3 {
    color: #cc9661;
    font-size:40px;
    font-style: italic;
    font-family: Arial, Helvetica, sans-serif;
}
.contactus>p {
    color: #cc9661;
    font-size: 25px;
}

.message{
    height: 200px !important;
}